import React from 'react'
import Firebase from './FirebaseController'

const FirebaseContext = React.createContext(null)
export { FirebaseContext }

const firebaseInit = ({ children }) => {
  return (
    <FirebaseContext.Provider value={ new Firebase() }>
      { children }
    </FirebaseContext.Provider>
  )
}

export default firebaseInit