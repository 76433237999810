import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom';

export default function Test(){
  const history = useHistory()
  let match = useRouteMatch();
  console.log('history', history)
  console.log('match', match)
  
  return(
    <h1>Test</h1>
  )
}