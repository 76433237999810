import React, { useContext, useEffect } from 'react'
import { TitlerLiveContext } from '../utils/TitlerLive'
import { Row, Col, Typography, List, Avatar, Card, Button } from 'antd'
import { useHistory } from 'react-router-dom';
import { CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import numeral from 'numeral'

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseContext } from '../utils/Firebase';

function PromptList(){
  const titlerLive = useContext(TitlerLiveContext)
  const firebase = useContext(FirebaseContext)
  const dispatch = useDispatch()
  const history = useHistory()
  const selectedPublisher = useSelector(state => state.selectedPublisher)
  const selectedPrompt = useSelector(state => state.selectedPrompt)
  const feed = useSelector(state => state.feed)
  const promptArr = useSelector(state => state.promptArr)
  const isLoading = useSelector(state => state.promptArrIsLoading)

  useEffect(()=>{
    if(!selectedPublisher)return
    firebase.firestore.collection('titlerLive').doc(selectedPublisher.id)
      .onSnapshot(snap => {
        if(snap.exists){
          firebase.firestore.collection('prompts').doc(snap.data().promptId)
            .get()
            .then(docRef => {
              if(docRef.exists){
                dispatch({type:'SET_SELECTED_PROMPT', payload: docRef.data()})
              }
            })
        }
      })
  },[selectedPublisher])

  useEffect(()=>{
    if(!feed){
      history.push('/feeds')
    }
  },[feed])

  useEffect(()=>{
    if(!feed)return
    firebase.getPrompts(feed.id)
  },[feed])

  useEffect(()=>{
    if(!selectedPrompt)return
    let prompt = {...selectedPrompt}
    titlerLive.updateVariables({
      "Text": prompt.text, 
      "Response1": prompt.responses[0], 
      "Response2": prompt.responses[1], 
      "Response1Value" : prompt.responseCountByChoice ? numeral(Math.round(prompt.responseCountByChoice[prompt.responses[0]] / prompt.responseCount * 100)).format('00') : numeral(0).format('00'),
      "Response2Value" : prompt.responseCountByChoice ? numeral(Math.round(prompt.responseCountByChoice[prompt.responses[1]] / prompt.responseCount * 100)).format('00') : numeral(0).format('00')
    })
  },[selectedPrompt])

  const handleClick = (prompt) => {
    firebase.firestore.collection('titlerLive').doc(selectedPublisher.id).set({promptId:prompt.id})
  }

  useEffect(()=>{
    if(selectedPrompt){
      promptArr.forEach(promptObj => {
        if(selectedPrompt.id === promptObj.id){
          titlerLive.updateVariables({
            "Response1Value" : promptObj.responseCountByChoice ? numeral(Math.round(promptObj.responseCountByChoice[promptObj.responses[0]] / promptObj.responseCount * 100)).format('00') : numeral(0).format('00'),
            "Response2Value" : promptObj.responseCountByChoice ? numeral(Math.round(promptObj.responseCountByChoice[promptObj.responses[1]] / promptObj.responseCount * 100)).format('00') : numeral(0).format('00')
          })
        }
      })

    }
  },[promptArr, selectedPrompt, titlerLive])

  return(
    feed 
      ? 
    <Row gutter={[16,16]}>
      <Col span={24}>
        <Button type='link' icon={<ArrowLeftOutlined/>} onClick={() => history.push('')}>Back to Feeds</Button>
        <Typography.Title><Avatar src={feed.picture}/> {feed.title}</Typography.Title>
        <Typography.Text>Please Select a Prompt</Typography.Text>
      </Col>
      <Col span={24}>
        <List
          style={{width:500}}
          itemLayout="horizontal"
          dataSource={promptArr}
          loading={isLoading}
          renderItem={item => {
            return(
            <List.Item
              style={{width:'100%'}}
              >
              <Card
                hoverable
                onClick={() => handleClick(item)}
                style={{width:'100%'}}
                cover={item.photoURL ? <img alt="example" src={item.photoURL} /> : null}
              >
                <Row justify='space-between'>
                  <Col>
                    <Typography.Title level={4} style={{padding:0, margin:0, marginBottom:10}}>{item.text}</Typography.Title>
                  </Col>
                  <Col>
                    {selectedPrompt && item.id === selectedPrompt.id ? <CheckCircleOutlined style={{color:'green', fontSize:24}}/> : null}
                  </Col>
                </Row>
                <Row justify='space-between'>
                  <Col>
                    <Row align='middle'>
                      <Typography.Title level={4} style={{padding:0, margin:0, marginRight: 10 }}>
                        {item.responseCountByChoice ? Math.round(item.responseCountByChoice[item.responses[0]] / item.responseCount * 100) : 0}
                        <Typography.Text style={{opacity:0.4, fontSize:'0.8em'}}>
                          %
                        </Typography.Text>
                      </Typography.Title>
                      <Typography.Text>
                        {item.responses[0]}
                      </Typography.Text>
                    </Row>
                  </Col>
                  <Col>
                    <Row align='middle'>
                      <Typography.Text>
                        {item.responses[1]}
                      </Typography.Text>
                      <Typography.Title level={4} style={{padding:0, margin:0, marginLeft: 10 }}>
                        {item.responseCountByChoice ? Math.round(item.responseCountByChoice[item.responses[1]] / item.responseCount * 100) :  0}
                        <Typography.Text style={{opacity:0.4, fontSize:'0.8em'}}>
                          %
                        </Typography.Text>
                      </Typography.Title>
                    </Row>
                  </Col>

                </Row>
              </Card>
            </List.Item>
            )
          }}
        />
      </Col>
    </Row>
    : 
    null
  )
}

export default PromptList