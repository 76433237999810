import React from 'react'
import TitlerLive from './TitlerLiveController'

const TitlerLiveContext = React.createContext(null)

const titlerLiveInit = ({ children }) => {
  return (
    <TitlerLiveContext.Provider value={ new TitlerLive() }>
      { children }
    </TitlerLiveContext.Provider>
  )
}

export { TitlerLiveContext }
export default titlerLiveInit
