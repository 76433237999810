import { ServiceHandler } from './js/servicehandler.js'

const INPUT_NAME = "Opine Titler Input";

export default class TitlerLive{
  constructor(){
    // CONNECT TO WEBSOCKET SERVER PORT
    if(
      window.location.hostname === 'localhost' ||
      window.location.hostname === 'titler-live-controller.web.app' ||
      window.location.hostname === 'titler-live-controller.firebaseapp.com' ||
      window.location.hostname === 'titler-live.opinelive'
    ){
      ServiceHandler.init('ws://192.168.50.9:9023')
    }else{
      ServiceHandler.init('ws://192.168.50.9:9023');
    }
    // console.log('window', window.location)
    ServiceHandler.onready = function () {
      console.log("ServiceHandler connected, ready to send/receive messages!");
    };
  }

  // initServiceHandler = (val) => {
  //   ServiceHandler.init(val);
  // }

  setVariables = (val) => {
    ServiceHandler.onready = () => {
      console.log('Service Handler Ready')
      ServiceHandler.scheduler.scheduleVariables(INPUT_NAME, "update", INPUT_NAME, val);
    }
  }

  updateVariables = (varObj) => {
    // ServiceHandler.onready = () => {
      ServiceHandler.scheduler.scheduleVariables(INPUT_NAME, "update", INPUT_NAME, varObj);
    // }
  }
}