import React, { useContext } from 'react'
import { Button } from 'antd'
import { FirebaseContext } from '../utils/Firebase'
import { setUser } from '../ducks/reducer'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

function SignOut({setUser}){
  const firebase = useContext( FirebaseContext )
  const history = useHistory()

  const handleClick = () => {
    firebase.signOut(setUser)
    setUser(null)
    history.push('/signin')
  }

  return(
    <Button onClick={handleClick}>Sign Out</Button>
  )
}

export default connect(null,{setUser})(SignOut)