import Cookies from 'universal-cookie'
const cookies = new Cookies()

var SessionStorage = (() => {
  var serverAddress = "";

  const getAll = () => {
    console.log('cookies',cookies.getAll())
    // return full_name;    // Or pull this from cookie/localStorage
  };

  const getCookie = (val) => {
    serverAddress = cookies.get(val)
    return serverAddress
  }

  const setServerAddress = (address) => {
    // full_name = name;     
    // Also set this in cookie/localStorage
    cookies.set('serverAddress', address, { path: '/' });
  };

  const removeCookie = (name) => {
    cookies.remove(name)
  }

  const setWebSocketError = (bol) => {
    cookies.set('webSocketError', bol, { path: '/'})
  }

  const cookieListener = async (setObserver) => {
    return cookies.addChangeListener(() => {
      const error = cookies.get('webSocketError')
      setObserver(error)
    })
  }

  return {
    getAll: getAll,
    setServerAddress: setServerAddress,
    getCookie: getCookie,
    removeCookie: removeCookie,
    setWebSocketError: setWebSocketError,
    cookieListener: cookieListener
  }

})();

export default SessionStorage;