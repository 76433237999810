import React, { useEffect, useContext, useState } from 'react';
import './App.css';
import Routes from './routes'
import { Layout, Typography, Badge, Row, Col } from 'antd';
import SignOut from './pages/SignOut';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from './utils/Firebase';
import SignIn from './pages/SignIn';
import { useSelector, useDispatch } from 'react-redux';
import Session from './utils/Session';
// import { TitlerLiveContext } from './utils/TitlerLive';

const { Header, Content, Footer } = Layout;

function App() {
  const firebase = useContext(FirebaseContext)
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.user)
  const [ isLoadingUser, setIsLoadingUser ] = useState(true)
  const [ observer, setObserver ] = useState(false)

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(
      async authUser => {
        if (authUser) {
          firebase.findUser(authUser.uid)
            .then(res => setIsLoadingUser(false))
            .catch(err => {
              history.push('/signin')
              setIsLoadingUser(false)
            })
          }else{
            dispatch({type:'SET_USER', payload:user})
            history.push('/signin')
            setIsLoadingUser(false)
          }
      }
    )
    return () => unsubscribe()
  },[firebase, history, dispatch])

  useEffect(()=>{
    Session.cookieListener(setObserver)
  },[])

  return (
    isLoadingUser
    ?
      <Row justify="center" style={{background:'white', height:'100vh'}}>
        <Col>
          <Typography.Text>Loading...</Typography.Text>
        </Col>
      </Row>
    :
      user
      ?
        <Layout className="layout" style={{background:'white', height:'100vh', overflowY:'scroll'}}>
          <Content style={{width:'100%', height:'100%'}}>
            <Header style={{ background:'white', paddingTop:20, width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <Typography.Title level={3}>
                {!isLoadingUser && user ? `Welcome ${user.name}` : `Opine Titler`}
              </Typography.Title>
              {/* <Typography.Text>{serverAddress}</Typography.Text> */}
                {observer === 'true'
                  ?
                    <Badge color='red' offset={[3,28]}>
                      <Typography.Text style={{color:'red', fontWeight:600}}>Titler Live Offline</Typography.Text>
                    </Badge>
                  :
                    <Badge color='green' offset={[3,28]}>
                      <Typography.Text style={{color:'green'}}>Titler Live Online</Typography.Text>
                    </Badge>
                }
              <SignOut/>
            </Header>
            <Row style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
              <Col style={{ padding: 50, maxWidth: 600}}>
                {!isLoadingUser && user ? <Routes /> : <SignIn/>}
              </Col>
            </Row>
            <Footer style={{position:'absolute', textAlign: 'center', width:'100%', bottom:0, background:'white'}}>
              Opine Inc ©2021
            </Footer>
          </Content>
        </Layout>
      : 
        <Content className="layout" style={{background:'white', height:'100vh', overflowY:'scroll'}}>
          <Header style={{ background:'white', paddingTop:20 }}>
            <Typography.Title level={3}>
              Welcome to Opine + Titler Live
            </Typography.Title>
          </Header>
          <SignIn/>
          <Footer style={{position:'absolute', textAlign: 'center', width:'100%', bottom:0, background:'white'}}>
            Opine Inc ©2021
          </Footer>
        </Content>
  );
}

export default App