import React, { useEffect, useContext, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, List, Card, Avatar, Typography } from 'antd'
import { FirebaseContext } from '../utils/Firebase';
import { useHistory } from 'react-router-dom';

export default function PublishersList(){
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const publisherIds = useSelector(state => state.user.authorizedPublisherIds)
  const publisherArr = useSelector(state => state.publisherArr)
  const user = useSelector(state => state.user)
  const [ isLoading, setIsLoading ] = useState(true)
  const [ publishers, setPublishers ] = useState()

  useEffect(()=>{
    let newArr = [...publisherArr]
    newArr.unshift(user)
    setPublishers(newArr)
  },[publisherArr])

  useEffect(()=>{
    if(!publisherIds || publisherIds.length > 0){
      firebase.getPublishers(publisherIds).then(()=>{
        setIsLoading(false)
      })
    }
  },[publisherIds])

  const handleClick = (publisherObj) => {
    dispatch({type:'SET_SELECTED_PUBLISHER', payload:publisherObj})
    history.push('/feeds')
  }

  return(
    <Row gutter={[16,16]}>
      <Col span={24}>
        <Typography.Title>Authorized Publishers</Typography.Title>
        <Typography.Text>Please Select a Publisher to select Feeds</Typography.Text>
      </Col>
      <Col span={24}>
        <List
          style={{
            cursor:'pointer'
          }}
          itemLayout="horizontal"
          dataSource={publishers}
          loading={isLoading}
          renderItem={item => (
              <List.Item>
                <Card
                  onClick={()=>handleClick(item)}
                  hoverable
                  style={{width:'100%'}}
                >
                  <Card.Meta
                    avatar={<Avatar src={item.profilePic} />}
                    title={item.name}
                    description={item.description}
                  />
                </Card>
              </List.Item>
          )}
        />
      </Col>
    </Row>
  )
}