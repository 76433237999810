const initialState = {
  feedArr: [],
  promptArr: [],
  feed: null,
  user: null,
  feedArrIsLoading: true,
  promptArrIsLoading: true,
  serverAddress: null,
  selectedPrompt: null,
  publisherArr: [],
  selectedPublisher: null
}

// ACTION DESCRIPTORS
const SET_SELECTED_FEED = 'SET_SELECTED_FEED'
const SET_USER = 'SET_USER'
const SET_FEED_ARR = 'SET_FEED_ARR'
const SET_PROMPT_ARR = 'SET_PROMPT_ARR'
const SET_FEED_ARR_IS_LOADING = 'SET_FEED_ARR_IS_LOADING'
const SET_PROMPT_ARR_IS_LOADING = 'SET_PROMPT_ARR_IS_LOADING'
const SET_SERVER_ADDRESS = 'SET_SERVER_ADDRESS'
const SET_SELECTED_PROMPT = 'SET_SELECTED_PROMPT'
const SET_PUBLISHER_ARR = 'SET_PUBLISHER_ARR'
const SET_SELECTED_PUBLISHER = 'SET_SELECTED_PUBLISHER'

// ACTION BUILDERS
export function setSelectedFeed(feedObj){
  return{
    type: SET_SELECTED_FEED,
    payload: feedObj
  }
}

export function setUser(userObj){
  return{
    type: SET_USER,
    payload: userObj
  }
}

export function setFeedArr(arr){
  return{
    type: SET_FEED_ARR,
    payload: arr
  }
}

export function setFeedArrIsLoading(bol){
  return{
    type: SET_FEED_ARR_IS_LOADING,
    payload: bol
  }
}

export function setPromptArr(arr){
  return{
    type: SET_PROMPT_ARR,
    payload: arr
  }
}

export function setPromptArrIsLoading(bol){
  return{
    type: SET_PROMPT_ARR_IS_LOADING,
    payload: bol
  }
}

export function setServerAddress(val){
  return{
    type: SET_SERVER_ADDRESS,
    payload: val
  }
}

export function setSelectedPrompt(val){
  return{
    type: SET_SELECTED_PROMPT,
    payload: val
  }
}


export default function reducer(state = initialState, action){
  switch( action.type){
    case SET_SELECTED_FEED:
      return {...state, feed: action.payload}

    case SET_USER:
      return {...state, user: action.payload}

    case SET_FEED_ARR:
      return {...state, feedArr: action.payload}

    case SET_FEED_ARR_IS_LOADING:
      return {...state, feedArrIsLoading: action.payload}

    case SET_PROMPT_ARR:
      return {...state, promptArr: action.payload}

    case SET_PROMPT_ARR_IS_LOADING:
      return {...state, promptArrIsLoading: action.payload}

    case SET_SERVER_ADDRESS:
      return {...state, serverAddress: action.payload}

    case SET_SELECTED_PROMPT:
      return {...state, selectedPrompt: action.payload}

    case SET_PUBLISHER_ARR:
      return {...state, publisherArr: action.payload}

    case SET_SELECTED_PUBLISHER:
      return {...state, selectedPublisher: action.payload}

    default: return state
  }
}