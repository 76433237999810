import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './ducks/store'
import TitlerLiveProvider from './utils/TitlerLive'
import FirebaseProvider from './utils/Firebase'
import { BrowserRouter } from 'react-router-dom'
import "antd/dist/antd.css";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <TitlerLiveProvider>
        <FirebaseProvider>
          <App />
        </FirebaseProvider>
      </TitlerLiveProvider>
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
