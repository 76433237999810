import React, { useContext, useState } from 'react'
import { Button, Col, Input, Row, Typography } from 'antd'
import { FirebaseContext } from '../utils/Firebase'
import { setUser } from '../ducks/reducer'
import { connect } from 'react-redux'

function SignIn({setUser}){
  const firebase = useContext(FirebaseContext)
  const [ email, setEmail ] = useState()
  const [ password, setPassword ] = useState()

  const handleClick = () => {
    firebase.signInWithEmail(email, password).then(doc => {
      setUser(doc)
    })
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  return(
    <Row gutter={[16,16]} style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems: 'center', height:'100%'}}>
      <Col>
        <Typography.Title>Welcome.</Typography.Title>
      </Col>
      <Col>
        <Typography.Text>Please Log In to Continue</Typography.Text>
      </Col>
      <Col>
        <Input bordered={false} onChange={handleEmailChange} type='email' placeholder='Email'/>
      </Col>
      <Col>
      <Input bordered={false} onChange={handlePasswordChange} type='password' placeholder='Password'/>
      </Col>
      <Col>
        <Button onClick={() => handleClick()}>Sign In</Button>
      </Col>
    </Row>
  )
}

export default connect(null,{ setUser })(SignIn)