import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import moment from 'moment'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}



export default class Firebase {
  constructor(){
    if (!app.apps.length) {
      app.initializeApp(config)
   }else {
      app.app(); // if already initialized, use that one
   }
    this.auth = app.auth()
    this.database = app.database()
    this.storage = app.storage()
    this.firestore = app.firestore().doc('versions/v1-prod')
    this.functions = app.functions()
    this.dispatch = useDispatch()
  }


  signInWithEmail = async (email,password) => {
    return await this.auth.signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // // Signed in
        var authUser = userCredential.user;
        if (authUser !== null ) {
          return this.findUser(authUser.uid)
          .then(doc => {
            return(doc) 
          })
          .catch(err => console.log('err', err))
        } 
      })
      .catch((error) => {
        message.error(error.message)
        console.log('err', error)
      });
  
  }

  findUser = async (publisherId) => {
    return this.firestore.collection('publisher').doc(publisherId).get().then( async docRef => {
      if(docRef.exists){
        let user = docRef.data()
        user = {...user, id: docRef.id}
        this.dispatch({type:'SET_USER', payload:user})
      }
    }).catch(err => {
      console.log('error:', err)
      message.error('Problem loading publisher, please try again')
      return  err
    })
  }

  // findAnything = async () => {
  //   this.firestore.collection('users').doc('dam44eAMWtYe4uPdVS8aNDk2cGF3').get().then(()=>message.success('Yay!'))
  // }

  getFeeds = async (publisherId) => {
    const start = moment().startOf('day').utc().format()
    const end = moment().endOf('day').utc().format()

    await this.firestore.collection('feeds')
      .where('publisherId', '==', publisherId)
      .where('eventScheduledTime', '>=', start)
      .where('eventScheduledTime', '<', end)
      .orderBy('eventScheduledTime', 'asc')
      .onSnapshot(snap=>{
      // let feedArr = []
      let feedArr = snap.docs.map(doc => {
        console.log('doc',doc.data())
        // feedArr.push(docRef.data())
        return doc.data()
      })
      console.log('feedArr', feedArr)
      this.dispatch({type:'SET_FEED_ARR', payload:feedArr})
    })
    return true
  }

  getPrompts = async (feedId) => {
    const query = await this.firestore.collection('prompts')
      .where('feedId', '==', feedId)
      .orderBy('timestamp', 'desc')
                    
    query.onSnapshot(async snap=>{
      let promptArr = []
      snap.forEach(docRef => {
        promptArr.push(docRef.data())
      })
      this.dispatch({type:'SET_PROMPT_ARR', payload:promptArr})
      this.dispatch({type:'SET_PROMPT_ARR_IS_LOADING', payload:false})
    })
    return true
  }

  getPublishers = async (pubIdArr) => {
    let publisherArr = await Promise.all(await pubIdArr.map(pubId =>{
      return this.firestore.collection('publisher').doc(pubId).get().then(docRef=>{
        if(docRef.exists){
          return {...docRef.data(),id:docRef.id}
        }
      })
    }))
    this.dispatch({ type: 'SET_PUBLISHER_ARR', payload: publisherArr })
  }

  signOut = () => {
    this.auth.signOut()
  }

}