import React, { useContext, useEffect, useState } from 'react'
import { Row, List, Avatar, Col, Typography, Card } from 'antd'
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseContext } from '../utils/Firebase';

function FeedsList(){
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const feedArr = useSelector(state => state.feedArr)
  const selectedPublisher = useSelector(state => state.selectedPublisher)
  // const [ feedArr, setFeedArr ] = useState()
  const [ isLoading, setIsLoading ] = useState()

  console.log('user', user)
  console.log('feedArr', feedArr)

  useEffect(()=>{
    if(!selectedPublisher){
      history.push('/')
    }
  },[selectedPublisher])

  useEffect(()=>{
    if(!selectedPublisher)return
    firebase.getFeeds(selectedPublisher.id).then(() => {
      setIsLoading(false)
    })
  },[user, firebase])

  const handleClick = (feedObj) => {
    dispatch({type:'SET_SELECTED_FEED', payload:feedObj})
    history.push('/prompts')
  }

  return(
    <Row gutter={[16,16]}>
      <Col span={24}>
        <Typography.Title>Upcoming Feeds</Typography.Title>
        <Typography.Text>Please Select a Feed</Typography.Text>
      </Col>
      <Col span={24}>
        <List
          style={{
            cursor:'pointer'
          }}
          itemLayout="horizontal"
          dataSource={feedArr}
          loading={isLoading}
          renderItem={item => (
              <List.Item
                onClick={() => handleClick(item)}
              >
                <Card
                  hoverable
                  style={{width:'100%'}}
                >
                  <Card.Meta
                    avatar={<Avatar src={item.picture} />}
                    title={item.title}
                    description={item.eventName}
                  />
                </Card>
              </List.Item>
          )}
        />
      </Col>
    </Row>
  )
}

export default FeedsList