import React, { useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import FeedsList from './pages/FeedsList'
import PromptList from './pages/PromptList'
import Test from './pages/Test'
import SignIn from './pages/SignIn'
import PublishersList from './pages/PublishersList'

function Routes(){
  const history = useHistory()

  useEffect(()=>{
    let path = history.location.pathname
    if( path === '/signup' || path === '/signin' ){
      history.push('/')
    }
  },[history])

  return(
    <Switch>
      <Route exact path={`/`} component={PublishersList}/>
      <Route path={`/signin`} component={SignIn}/>
      <Route path={`/feeds`} component={FeedsList}/>
      <Route path={`/prompts`} component={PromptList}/>
      <Route path={`/test`} component={Test}/>
    </Switch>
  )
}

export default Routes